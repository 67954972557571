import { apolloClient } from '@/apollo'

import GET_PRESETS from '@/gql/query/getPresets.gql'
import CREATE_PRESET from '@/gql/mutation/preset/createPreset.gql'
import UPDATE_PRESET from '@/gql/mutation/preset/updatePreset.gql'
import DELETE_PRESET from '@/gql/mutation/preset/deletePreset.gql'

export default {
  namespaced: true,
  state: {
    paperMenuPresets: [],
  },
  getters: {
    paperMenuPresets(state) {
      return state.paperMenuPresets
    },
  },
  mutations: {
    setPaperMenuPresets(state, data) {
      state.paperMenuPresets = data
    },
  },
  actions: {
    setPaperMenuPresets({ commit }, data) {
      commit('setPaperMenuPresets', data)
    },
    async getPaperMenuPresets({ rootGetters, dispatch }) {
      const response = await apolloClient.query({
        query: GET_PRESETS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setPaperMenuPresets', response.data.getPaperMenuPresets)
    },

    async createPaperMenuPreset({ rootGetters, dispatch, getters }, data) {
      const response = await apolloClient.query({
        query: CREATE_PRESET,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
          settings: data,
        },
      })
      const preset = response.data.createPreset

      dispatch('setPaperMenuPresets', [...getters.paperMenuPresets, preset])
      return preset.id
    },
    async updatePaperMenuPreset({ dispatch, getters }, data) {
      const response = await apolloClient.query({
        query: UPDATE_PRESET,
        variables: {
          id: data.id,
          settings: data.settings,
        },
      })
      dispatch('setPaperMenuPresets', getters.paperMenuPresets.map(i => (i.id === data.id ? response.data.updatePreset : i)))
    },
    async deletePaperMenuPreset({ dispatch, getters }, data) {
      await apolloClient.query({
        query: DELETE_PRESET,
        variables: {
          id: data,
        },
      })
      dispatch('setPaperMenuPresets', getters.paperMenuPresets.filter(i => i.id !== data))
    },
  },
}
