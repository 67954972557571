import { apolloClient } from '@/apollo'
import Vue from 'vue'

import GET_MENUS from '@/gql/query/menu-managment/getMenus.gql'
import GET_MENU_ITEMS from '@/gql/query/menu-managment/getMenuItems.gql'
import CREATE_MENU from '@/gql/mutation/menu/createMenu.gql'
import CREATE_MENU_ITEM from '@/gql/mutation/menuItem/createMenuItem.gql'
import GET_MENUS_STATE from '@/gql/query/menu-managment/getMenusState.gql'

export default {
  namespaced: true,
  state: {
    isMenusFetched: false,
    menus: [],

    isMenuItemsFetched: false,
    menuItems: [],

    menusState: [],
  },
  getters: {
    isMenusFetched(state) {
      return state.isMenusFetched
    },
    menus(state) {
      return state.menus
    },

    isMenuItemsFetched(state) {
      return state.isMenuItemsFetched
    },
    menuItems(state) {
      return state.menuItems
    },

    menusState(state) {
      return state.menusState
    },
  },
  mutations: {
    setIsMenusFetched(state, data) {
      state.isMenusFetched = data
    },
    setMenus(state, data) {
      state.menus = data
    },

    setIsMenuItemsFetched(state, data) {
      state.isMenuItemsFetched = data
    },
    setMenuItems(state, data) {
      state.menuItems = data
    },

    setMenusState(state, data) {
      state.menusState = data
    },

    updateMenu(state, [id, param, value]) {
      const itemIndex = state.menus.findIndex(i => i.id === id)
      if (itemIndex !== -1) {
        // eslint-disable-next-line no-undef
        Vue.set(state.menus[itemIndex], param, structuredClone(value))
      }
    },
    updateMenuItem(state, [id, param, value]) {
      const itemIndex = state.menuItems.findIndex(i => i.id === id)
      if (itemIndex !== -1) {
        // eslint-disable-next-line no-undef
        Vue.set(state.menuItems[itemIndex], param, structuredClone(value))
      }
    },
  },
  actions: {
    setMenus({ commit }, data) {
      commit('setMenus', data)
    },
    async getMenus({ rootGetters, dispatch, commit }) {
      const response = await apolloClient.query({
        query: GET_MENUS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setMenus', response.data.getMenus)
      commit('setIsMenusFetched', true)
    },

    setMenuItems({ commit }, data) {
      commit('setMenuItems', data)
    },
    async getMenuItems({ rootGetters, dispatch, commit }) {
      const response = await apolloClient.query({
        query: GET_MENU_ITEMS,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setMenuItems', response.data.getMenuItems)
      commit('setIsMenuItemsFetched', true)
    },

    updateMenu({ commit }, [id, param, value]) {
      commit('updateMenu', [id, param, value])
    },
    updateMenuItem({ commit }, [id, param, value]) {
      commit('updateMenuItem', [id, param, value])
    },

    async createMenu({ rootGetters }, menuName) {
      const response = await apolloClient.mutate({
        mutation: CREATE_MENU,
        variables: {
          restaurant: rootGetters['restaurant/restaurant'].id,
          name: menuName,
        },
      })

      // eslint-disable-next-line no-undef
      return structuredClone(response.data.createMenu)
    },
    async createMenuItem({ rootGetters }, [itemName, itemType, suggestionName]) {
      const response = await apolloClient.mutate({
        mutation: CREATE_MENU_ITEM,
        variables: {
          restaurant: rootGetters['restaurant/restaurant'].id,
          name: itemName,
          type: itemType,
          suggestionName,
        },
      })

      // eslint-disable-next-line no-undef
      return structuredClone(response.data.createMenuItem)
    },

    setMenusState({ commit }, data) {
      commit('setMenusState', data)
    },
    async getMenusState({ rootGetters, dispatch }) {
      const response = await apolloClient.query({
        query: GET_MENUS_STATE,
        variables: {
          restaurantId: rootGetters['restaurant/restaurant'].id,
        },
      })
      dispatch('setMenusState', response.data.getMenusState)
    },

    clearMenuManagementData({ commit }) {
      commit('setIsMenusFetched', false)
      commit('setMenus', [])

      commit('setIsMenuItemsFetched', false)
      commit('setMenuItems', [])
    },
  },
}
